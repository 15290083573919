.special-approval {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.headings {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h1 {
    font-size: var(--font-size-28);
    font-weight: 700;
  }

  p {
    font-size: var(--font-size-16);
    font-weight: 600;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.student-programs {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.program {
  flex: 1;
}
