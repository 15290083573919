@import '../../../../assets/styles/main.scss';
.email-tab-container {
  display: flex;
  height: 70vh;
  @include respond-to(sm) {
    flex-direction: column;
  }
}
.email-nav {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-right: 1px solid #dadada;
  padding-right: 1.5rem;
  justify-content: space-between;
  @include respond-to(sm) {
    border-right: none;
  }
}
.header-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.email-content-diplay {
  flex: 4;
  margin-left: 1.5rem;
  @include respond-to(sm) {
    min-height: 40vh;
    margin-left: 0px;
    margin-top: 3rem;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // gap: 3rem;
  width: 100%;
}
.filters {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.header-buttons {
  display: flex;
  gap: 1rem;
  background-color: #e9f3ff;
  width: 100%;
  padding: 0.5rem;
  border-radius: 12px;

  .button {
    background-color: transparent !important;
    color: black;
    font-weight: 600;
    flex: 1;
    img {
      filter: $filter_black;
    }
    &:hover {
      background-color: red !important;
      color: white !important;
      border-color: red !important;
      img {
        filter: $filter_white !important;
      }
    }
  }
}
.emails-bar {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  // text-transform: capitalize;

  .headings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    .title {
      font-size: var(--font-size-24);
      font-weight: 600;
    }
    .title-container {
      width: 100%;
      border-bottom: 1px solid #dadada;
      padding-bottom: 1rem;
    }
  }
  .date {
    display: flex;
    align-items: center;
    gap: 0.2rem;

    .date-text {
      font-size: var(--font-size-14);
      font-weight: 600;
      margin-top: 2px;
    }
  }
}

.filter-btn {
  cursor: pointer;
}
.content-email {
  margin-top: 3rem;
  border-bottom: 2px solid #dadada;
  padding-bottom: 10px;
  max-height: 40vh;
    overflow-y: scroll;
}

.attachments {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .attachmentsCont {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;

    .attachmentsSubCont {
      display: flex;
      gap: 0.2rem;
      align-items: center;

      img {
        width: 19.25px;
        height: 22px;
      }
      p {
        padding: 9px 18px;
        border-radius: 37px;
        background: #e6eefa;
      }
    }
  }
}

.profile {
  display: flex;
  gap: 1rem;
}
.profile-info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  .from {
    font-size: var(--font-size-16);
    font-weight: 600;
  }
  .to {
    font-size: var(--font-size-14);
    font-weight: 500;
    span {
      font-size: var(--font-size-14);
      font-weight: 600;
    }
  }
}
