@import '../../../../../../../assets/styles/main.scss';

.tab {
  min-width: 10px !important;
}
.application {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.course-wrapper {
  display: flex;
  gap: 2vh;
  width: 100%;
  > * {
    flex: 1;
  }
  @include respond-to(lg) {
    width: 100%;
  }
}
.filters {
  display: flex;
  gap: 1rem;
  width: 40%;
  justify-content: flex-start;
  align-items: flex-end;
  @include respond-to(lg) {
    width: 70%;
  }
  @include respond-to(sm) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}
