@import '../../../../assets/styles/main';

.label {
  font-size: var(--font-size-14);
  font-weight: 600;
  text-transform: capitalize;
  color: $primary_color;
}

.hover {
  cursor: pointer;
}
