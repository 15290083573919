@import '../../../../../assets/styles/main';

.select-date-container {
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  .select-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    border: 1px solid $primary_color;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: darken($primary_color, 10%);
    }

    .select-display {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      .select {
        outline: none;
        border: none;
        width: 200px;
        color: $primary_color;
        background-color: $white;
        padding: 0.5rem;
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        position: relative;

        &::-webkit-calendar-picker-indicator {
          display: none;
        }
      }

      .placeholder {
        color: #1b224c;
      }

      img {
        margin-left: auto;
      }
      .dateTime-img {
        margin-left: 0px;
      }
    }
  }
}
.error-message {
  font-size: var(--font-size-12);
  color: red;
}
