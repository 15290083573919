@import '../../../../assets/styles//main.scss';
.all_applications_cont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .search_cont div {
    width: 50em;
    @include respond-to(sm) {
      width: 90%;
    }
  }
}
.all-applications {
  display: grid;
  gap: 2rem;
  // padding: 0rem 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  @include respond-to(lg) {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }
}

.loader-container {
  display: flex;
  height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
