@import '../../../../../../assets/styles/main';
.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include respond-to(sm) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

.profile-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .header-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
      cursor: pointer;
    }
    p {
      color: $primary_color;
      font-weight: 700;
      text-transform: capitalize;
      font-size: var(--font-size-20);
    }
  }

  .text-wrapper {
    display: flex;
    gap: 1rem;
    p {
      color: $primary_color;
      font-weight: 600;
      text-transform: capitalize;
      font-size: var(--font-size-14);
      span {
        font-weight: 500;
      }
    }
    @include respond-to(sm) {
      flex-direction: column;
    }
  }
}

.waiting-wrapper {
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
  align-items: flex-end;
  p {
    color: #ffb202;
    font-weight: 500;
    text-transform: capitalize;
    font-size: var(--font-size-14);
    span {
      font-weight: 600;
    }
  }
}
