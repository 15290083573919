@import '../../../assets/styles/main';

.document-view {
  display: flex;
  gap: 0.7rem;
  width: 200px;
  color: $primary_color;
  align-items: center;
}
.file-img {
  height: 20px;
}
.info {
  white-space: nowrap;
}
.title {
  font-size: var(--font-size-12);
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 115px;
}
.text {
  font-size: var(--font-size-10);
  font-weight: 400;
}

.dots {
  cursor: pointer;
}
