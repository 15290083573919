@import '../../../../assets/styles/main';
.input-container {
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  .input-wrapper {
    position: relative;
    display: flex;
    min-height: 2.5rem;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid $primary_color;
    overflow: hidden;
    align-items: center;
    min-height: 47px;

    .attachments {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    .attachment-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.3rem 0.4rem;
      border-bottom: 1px solid $primary_color_hover;
      background-color: #e6eefa;
      border-radius: 20px;
      width: 100px;

      .file-name {
        flex-grow: 1;
        color: $primary_color;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: var(--font-size-12);
      }

      .remove-button {
        background: transparent;
        border: none;
        color: red;
        cursor: pointer;
        font-size: 1rem;
        padding: 0;
        margin-left: 0.5rem;
      }
    }

    .input {
      outline: none;
      border: none;
      width: 100%;
      color: $primary_color;
      background-color: transparent;
      text-transform: capitalize;
      font-size: var(--font-size-14);
      display: none;
    }

    .add-more {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0rem 0.8rem;
      background-color: red;
      border-radius: 5px;
      cursor: pointer;
      min-height: 80%;
      width: 100px;
      max-height: 40px;
      margin-left: auto;
      margin-right: 4px;
      box-sizing: border-box;
      white-space: nowrap;
      &:hover {
        background-color: white;
        border: 1px solid red;

        p {
          color: red;
        }
      }

      p {
        color: white;
        font-size: 700;
        font-size: var(--font-size-14);
        text-transform: capitalize;
      }
    }
  }

  .error-message {
    font-size: var(--font-size-12);
    color: red;
  }

  .custom-upload-button {
    color: white;
    border-radius: 0.3rem;
    text-align: center;
    font-size: var(--font-size-14);
    border: none;
    transition: background-color 0.3s ease;
    width: 40px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
