@import '../../../../../../assets/styles/main.scss';

.course-wrapper {
  display: flex;
  gap: 2vh;

  > * {
    flex: 1;
  }
}
.course-detail-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .button {
    margin-top: 2rem;
  }
}

.course-detail {
  display: flex;
  width: 100%;
  padding: 0.2rem 0rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.274);
  > * {
    flex: 1;
  }
}

.title {
  font-size: var(--font-size-14);
  color: $primary_color;
  font-weight: 600;
}
.attention {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #fff2d5;
  padding: 0.8rem;
  border-radius: 40px;
  p {
    font-weight: 600;
    font-size: var(--font-size-14);
  }
  img {
    height: 18px;
  }
}
.sub-title {
  font-size: var(--font-size-14);
  color: $primary_color;
}
.course-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.modal {
  width: clamp(300px, 55vw, 900px);
  max-width: 900px;
}
