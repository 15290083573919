@import '../../../../../../assets/styles/main.scss';

.tab {
  min-width: 10px !important;
}
.application {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.term {
  width: clamp(200px, 20vw, 30vw);
}

.course-wrapper {
  display: flex;
  gap: 2vh;

  > * {
    flex: 1;
  }
}
.course-detail-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .button {
    margin-top: 2rem;
  }
}

.course-detail {
  display: flex;
  width: 100%;
  padding: 1rem 0rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.274);
  > * {
    flex: 1;
  }
}

.title {
  font-size: var(--font-size-14);
  color: $primary_color;
  font-weight: 600;
}

.sub-title {
  font-size: var(--font-size-14);
  color: $primary_color;
}
.course-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.modal {
  width: clamp(300px, 55vw, 900px);
  max-width: 900px;
}
.courseFormCont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @include respond-to(sm) {
    grid-template-columns: 1fr;
  }
}
