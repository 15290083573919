@import '../../../assets/styles/main';

.portal-container {
  position: relative;
}
.portal-content {
  position: absolute;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  right: 0px;
  top: 25px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: max-content;
  // padding: 1rem;
  &.entering {
    opacity: 1;
    transform: translateY(0);
  }

  &.exiting {
    opacity: 0;
    transform: translateY(-10px);
  }

  .btn {
    display: block;
    padding: 1rem 0.8rem;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    min-width: 80px;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
      filter: $filter_black;
    }
    &:hover {
      background: #f0f0f0;
    }
    p {
      font-size: var(--font-size-14);
      font-weight: 600;
      text-transform: capitalize;
      height: 100%;
      margin-top: 3px;
    }
    .image {
      height: 20px;
    }
  }
}
.check {
  padding: 0.5rem;
}
.expend-btn {
  cursor: pointer;

  img {
    width: 1.3rem;
  }
}

.col-visibility {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 2rem 1rem 1rem;
  width: max-content;
  max-width: 300px;
}
.ss {
  // width: max-content;
}
