.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-class {
  width: clamp(330px, 90vw, 1000px) !important;
  max-width: 1000px;
}
