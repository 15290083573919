@import '../../../assets/styles/main';

.card-tag {
  border-radius: 15px;
  background-color: white;
  border: 3px solid transparent;
  cursor: pointer;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.106);
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1vh;
  transition: box-shadow 0.3s ease;
  &:hover {
    background-color: #ecf4ff;
  }
  .image {
    // background-color: #ecf4ff;
    width: 80px;
    height: 65px;
    border-radius: 15px;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
    }
  }

  p {
    color: #000;
    font-family: Raleway;
    font-size: var(--font-size-18);
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
  }
}

.card-tag.active {
  border-radius: 10px;
  border: 2px solid var(--Color-pink-pink-100, #f3223c);
  background: linear-gradient(
      0deg,
      var(--Color-base-white, #fff) 0%,
      var(--Color-base-white, #eff4fb) 100%
    ),
    var(--Color-base-white, #ecf4ff);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
}

.small {
  height: 50px !important;
  width: 70px !important;
}
