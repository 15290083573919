@import '../../../../../assets/styles/main.scss';

.user_header_cont {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  .subContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
  .parent {
    position: relative;

    .child {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
    .user-image {
      border-radius: 50%;
      height: 125px;
      width: 125px;
    }
  }
  .title_container {
    display: flex;
    justify-content: space-between;
    @include respond-to(md) {
      flex-direction: column;
      justify-content: flex-start;
    }
    .edit_title {
      display: flex;
      gap: 0.7rem;
      @include respond-to(md) {
        width: 100%;
      }
    }
    .desktop {
      @include respond-to(md) {
        display: none;
      }
    }
  }
  .horizontal_container {
    width: 100%;
    display: flex;

    .horizontal {
      width: 100%;
      height: 1.5px;
      background: transparent;
      border-top: 1px solid #d9d9d9;
    }
  }
  .details_subcontainer {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }
}
.mobile {
  display: none;
  @include respond-to(md) {
    display: block;
    padding-top: 10px;
  }
  .edit_title_mobile {
    display: flex;
    gap: 0.7rem;
    @include respond-to(md) {
      width: 100%;
    }
  }
}
