@import './assets/styles/main';

:root {
  --font-size-28: 1.75rem; /* 28px */
  --font-size-26: 1.625rem; /* 26px */
  --font-size-24: 1.5rem; /* 24px */
  --font-size-22: 1.375rem; /* 22px */
  --font-size-20: 1.25rem; /* 20px */
  --font-size-18: 1.125rem; /* 18px */
  --font-size-16: 1rem; /* 16px */
  --font-size-14: 0.875rem; /* 14px */
  --font-size-12: 0.75rem; /* 12px */
  --font-size-10: 0.625rem; /* 10px */
}
html {
  font-size: 100%;
  @include respond-to(xl) {
    font-size: 90.5%;
  }
  @include respond-to(lg) {
    font-size: 87.5%;
  }
  @include respond-to(sm) {
    font-size: 85.5%;
  }
}

.body-layout-width {
  width: 90%;
  margin: auto;
  @include respond-to(lg) {
    width: 90%;
  }
  @include respond-to(ms) {
    width: 85%;
  }
}

.course-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

img {
  user-select: none;
}

body {
  background-color: $colors-surface-secondary;
}

button {
  cursor: pointer;
}

