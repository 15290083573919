@import '../../../../../../assets/styles/main.scss';

.tab {
  min-width: 10px !important;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.application-container {
  width: 55%;
  @include respond-to(lg) {
    width: 100%;
  }
}
.button-container {
  display: flex;
  justify-content: space-between;
}

.application {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include respond-to(lg) {
    width: 100%;
  }
}
