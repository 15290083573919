@import '../../../../assets/styles/main';

.button {
  padding: 0.6rem 0.7rem;
  font-size: var(--font-size-16);
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border: none;
  color: $white;
  background-color: $primary_color;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  min-width: 100px;
  width: max-content;
  border: 2px solid transparent;

  &:hover {
    background-color: white;
    border: 2px solid $primary_color;
    color: $primary_color;
    img {
      filter: $filter_primary_color;
    }
  }

  &.default {
    background-color: $primary_color;
    &:hover {
      background-color: white;
    }
  }

  &.danger {
    background-color: $danger_color;
    img {
      filter: $filter_white;
    }
    &:hover {
      background-color: white;
      border: 2px solid $danger_color;
      color: #d72222;
      img {
        filter: $filterRed;
      }
    }
  }
  &.blue {
    background-color: $verify_color;
    &:hover {
      background-color: white;
      border: 2px solid $verify_color;
      color: $verify_color;
      img {
        filter: $filterBlue;
      }
    }
  }
  &.green {
    background-color: $active;
    &:hover {
      background-color: white;
      border: 2px solid $active;
      color: $active;
      img {
        filter: $filterGreen;
      }
    }
  }
}

.image-wrapper {
  max-width: 20px;
  max-height: 20px;
  img {
    max-width: 20px;
    max-height: 20px;
  }
}
.white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(18%) saturate(0%)
    hue-rotate(2deg) brightness(112%) contrast(100%);
}
