@import '../../../../../../../assets/styles/main.scss';

.tab {
  min-width: 10px !important;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include respond-to(sm) {
    grid-template-columns: 1fr;
  }
}
.application-container {
  width: 55%;
  @include respond-to(lg) {
    width: 100%;
  }
}
.application {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include respond-to(lg) {
    width: 100%;
  }

  .text {
    font-size: var(--font-size-12);
    font-weight: 500;
    color: $primary_color;
    span {
      color: red;
    }
  }
}
