@import '../../../../../../../assets/styles/main';

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.date-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  @include respond-to(xs) {
    width: 100%;
  }
}
.date {
  margin-top: 1rem;
}
