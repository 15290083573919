@import '../../../../assets/styles/main';

.textarea-container {
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  .textarea-wrapper {
    position: relative;
    display: flex;
    gap: 0.5rem;
    padding: 0.6rem 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid $primary_color;

    .textarea {
      outline: none;
      border: none;
      width: 100%;
      color: $primary_color;
      background-color: transparent;
      // text-transform: capitalize;
      resize: none;
      font-family: inherit;
      font-size: var(--font-size-16);
      padding: 0.5rem;
      min-height: 100px;

      &:focus {
        border-color: $primary_color;
      }

      &::placeholder {
        border-color: $placeholder;
      }
    }

    .placeholder {
      position: absolute;
      bottom: 5px;
    }
  }

  .text-svg {
    padding-top: 0.25rem;
  }
}

.disabled {
  border: 1px solid rgba(100, 100, 100, 0.541) !important;
  cursor: not-allowed;
  img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(8%) saturate(0%)
      hue-rotate(166deg) brightness(83%) contrast(86%);
  }
  .textarea {
    cursor: not-allowed;
  }
}

.label-disable {
  color: rgba(100, 100, 100, 0.678) !important;
}
.error-message {
  color: red;
  font-size: var(--font-size-12);
}
