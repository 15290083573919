@import '../../../../assets/styles/main';
.selectorCont {
  display: inline-flex;
  gap: 14px;
  padding: 10px 14px;
  border-radius: 40px;
  background: #0167b1;
  color: white;
  align-items: center;
  width: fit-content;
  @include respond-to(lg) {
    padding: 5px 10px;
  }
  h1 {
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @include respond-to(lg) {
      font-size: 14px;
    }
  }
  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%)
      hue-rotate(206deg) brightness(110%) contrast(101%);
    height: 20px;
    cursor: pointer;
    &:hover {
      filter: none;
    }
    @include respond-to(lg) {
      height: 15px;
    }
  }
}
