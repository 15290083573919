.attention-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.attention {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #fff2d5;
  padding: 0.8rem;
  border-radius: 40px;
  p {
    font-weight: 600;
    font-size: var(--font-size-14);
  }
  img {
    height: 18px;
  }
}

.checkboxes {
  display: flex;
  gap: 1rem;
}
