@import '../../../assets/styles/main';

.table-layout {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.table-container {
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  text-align: left;
  font-size: var(--font-size-14);
  color: $primary_color;
  padding: 2px 0px;
}
.table th {
  font-weight: 600;
}
.table td {
  font-weight: 500;
}

.default {
  color: black;
}

.red {
  th {
    color: red;
  }
  td {
    color: red;
  }
}

.green {
  color: green;
  th {
    color: green;
  }
  td {
    color: green;
  }
}
