@import '../../../../assets/styles/main';

.registrar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  background-color: $white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: $registrar-Zindex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @include respond-to(lg) {
    display: none;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: var(--font-size-28);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $primary_color;
  text-transform: capitalize;
  img {
    cursor: pointer;
  }
}
