@import '../../../assets/styles/main';

.accordion {
  border-radius: 10px;
  overflow: hidden;
}

.header {
  background-color: white;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
}

.content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.contentInner {
  padding: 0rem 2rem 1rem 2rem;
  background: #fff;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p {
    font-size: var(--font-size-16);
    font-weight: 600;
    text-transform: capitalize;
  }
}
.datArrow {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.icon {
  font-weight: bold;
  transition: transform 0.3s ease-out;
  user-select: none;
  img {
    filter: $disabledFilter;
  }
}

.openIcon {
  transform: rotate(180deg);
}

.open {
  max-height: 500px;
}

.closed {
  max-height: 0;
}
