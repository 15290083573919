@import '../../../assets/styles/main';

.header-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
    cursor: pointer;
  }
  p {
    color: $primary_color;
    font-weight: 700;
    text-transform: capitalize;
    font-size: var(--font-size-20);
  }
}
