$base-font-size: 16px;

$app-size: 181.25rem;

$screen-xs: 30em;
$screen-sm: 48em;
$screen-md: 64em;
$screen-lg: 75em;
$screen-xl: 90em;
$screen-xxl: 100em;

$side-bar-width: 15.625rem;

$header_zindex: 100;

@mixin respond-to($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: $screen-xs) {
      @content;
    }
  } @else if $breakpoint == sm {
    @media (max-width: $screen-sm) {
      @content;
    }
  } @else if $breakpoint == md {
    @media (max-width: $screen-md) {
      @content;
    }
  } @else if $breakpoint == lg {
    @media (max-width: $screen-lg) {
      @content;
    }
  } @else if $breakpoint == xl {
    @media (max-width: $screen-xl) {
      @content;
    }
  } @else if $breakpoint == xxl {
    @media (max-width: $screen-xxl) {
      @content;
    }
  }
}

$header_zindex: 100;
$registrar-Zindex: 100;
$modal-Zindex: 999;
$sideBar-Zindex: 1000;
