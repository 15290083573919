@import '../../..//../../../../assets/styles/main';

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  // border-bottom: 1px solid #dadada;
  width: 100%;
}
.extraTempl {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  width: 100%;
}
.subSection {
  display: flex;
  gap: 1rem;
}
.grid {
  width: 100%;
  display: flex;
  gap: 1rem;
}
.verticalLine {
  height: 100%;
}
.titleCont {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .addMore {
    font-size: var(--font-size-14);
    font-weight: 600;
    color: $verify_color;
    cursor: pointer;
    span{
      font-size: var(--font-size-18);
    }
    &:hover {
      color: $disabled;
    }
  }
}
.titleSubCont {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  p {
    font-size: var(--font-size-16);
    font-weight: 600;
    color: $primary_color;
  }
}
// .verticalLine {
//   // border: 1px solid #dadada;
// }
.inner-section {
  display: flex;
  gap: 1rem;
}

.title {
  font-size: var(--font-size-16);
  font-weight: 600;
  color: $primary_color;
}

.status-container {
  margin-top: 1rem;
  display: flex;
  gap: 3rem;
}
