@import '../../../../assets/styles/main';

.students-header-mobile {
  display: none;
  @include respond-to(lg) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: $header_zindex;
    display: flex;
    width: 100%;
    background-color: #e0edfd;
  }
  .header {
    @include respond-to(lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      width: 90%;
      margin: auto;
    }
  }

  .title {
    color: $primary_color;
    font-weight: 700;
    font-size: var(--font-size-22);
    text-transform: capitalize;
  }
}
