@import '../../../assets/styles/main';

.student-profile {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.card {
  display: flex;
  gap: 2rem;
  @include respond-to(md) {
    flex-direction: column;
    gap: 0.4rem;
  }
  .header-md {
    display: none;

    @include respond-to(md) {
      display: block;
    }
  }
}
.profile {
  display: flex;
  gap: 2vw;
}
.image-wrapper {
  width: clamp(50px, 10vw, 220px);
  height: clamp(50px, 10vw, 220px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  border-radius: 20px;
  @include respond-to(md) {
    width: clamp(50px, 20vw, 220px);
    height: clamp(50px, 20vw, 220px);
  }
  img {
    // width: 100%;
    height: 100%;
    border-radius: 20px !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  .header {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
    @include respond-to(md) {
      display: none;
    }
  }

  .info-wrapper {
    display: flex;
    gap: 7vw;
    margin-top: 1rem;

    @include respond-to(sm) {
      flex-direction: column;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 0.4em;
  }
}

.text-wrapper {
  display: flex;
  gap: 3vw;
  justify-content: baseline;
  align-items: center;
  flex-wrap: wrap;
}
.text {
  font-size: var(--font-size-12);
  color: #767a94;
  font-weight: 500;
}

////
///
///
///
///
///
.skeleton {
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.text {
  height: 1rem;
}

.rect {
  border-radius: 4px;
}

.circle {
  border-radius: 50%;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
