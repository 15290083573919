@import '../../../../../assets/styles/main';

.special-approval {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.headings {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h1 {
    font-size: var(--font-size-28);
    font-weight: 700;
  }

  p {
    font-size: var(--font-size-16);
    font-weight: 600;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.student-programs {
  display: flex;
  gap: 1rem;
  width: 100%;
  @include respond-to(sm) {
    flex-direction: column;
  }
}

.program {
  flex: 1;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
