@import '../../../../../assets/styles/main';

.select-container {
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  .selected-options {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    .selected-option {
      background-color: $primary_color;
      color: $white;
      padding: 0.3rem 0.5rem;
      border-radius: 0.5rem;
      font-size: var(--font-size-12);
    }
  }
  .select-flex {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 0.5rem;
    .clear {
      color: red;
      font-size: var(--font-size-14);
      font-weight: 600;
      text-decoration: underline;
      width: min-content;
      min-width: 110px;
      cursor: pointer;
    }
  }
  .select-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    border: 1px solid $primary_color;
    width: 100%;
    cursor: pointer;

    .svg {
      width: 20px;
    }

    &:focus {
      outline: none;
      border-color: darken($primary_color, 10%);
    }

    .select-display {
      display: flex;
    }

    .select {
      outline: none;
      border: none;
      width: 100%;
      color: $placeholder;
      background-color: $white;
      padding: 0.5rem;
      cursor: pointer;
      background-color: transparent;
      text-transform: capitalize;
      font-size: var(--font-size-14);
    }

    .arrow {
      margin-right: 5px;
      width: 15px;
    }
  }
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: $white;
  max-height: 40vh;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 0.1rem;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.dropdown-animation {
    opacity: 1;
    transform: translateY(0);
  }

  .option {
    display: flex;
    align-items: center;
    padding: 1.2rem;
    background-color: $white;
    color: $primary_color;
    cursor: pointer;
    z-index: 999;

    &:nth-child(odd) {
      background-color: #f4f4f4;
    }

    &:nth-child(even) {
      background-color: $white;
    }

    &.selected {
      background-color: lighten($primary_color, 70%);
    }

    &:hover {
      background-color: lighten($disabled, 0%);
    }

    .checkbox {
      margin-right: 0.5rem;
    }
  }

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background: $white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary_color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken($primary_color, 20%);
  }
}

.error-message {
  font-size: var(--font-size-12);
  color: red;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-transform: capitalize;
  font-size: var(--font-size-16);
  font-weight: 600;
  color: $primary_color;
}

.disable-scroll {
  overflow: hidden !important;
}

.expand-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 50%;
  padding: 0.1rem 0.3rem;
  height: 25px;
  margin-top: 4px;
  img {
    filter: $filterRed;
    height: 12px;
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-items-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  .selected-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: max-content;
    padding: 0.5rem;
    border-radius: 50px;
    background-color: #e0edfd;
    gap: 1rem;
    font-size: var(--font-size-14);
    font-weight: 600;
    span {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid rgb(0, 0, 255);
      padding: 0.2rem;
      img {
        transform: rotate(135deg);
        height: 11px;
        filter: brightness(0) saturate(100%) invert(14%) sepia(80%)
          saturate(2663%) hue-rotate(209deg) brightness(97%) contrast(116%);
      }
    }
  }
}

.no-options {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-16);
  font-weight: 600;
}
