@import '../../../../assets/styles/main';

.nav-link {
  width: calc(100% - 20px);
  height: 35px;
  background-color: $primary_color;
  color: $primary_color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  text-transform: capitalize;
  gap: 0.5rem;
  font-size: var(--font-size-16);
  font-weight: 700;
  transition: transform 0.3s ease-in-out;
  @include respond-to(lg) {
    background-color: transparent;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .link-image {
    width: 30.01px;
    height: 28.571px;
    filter: $filter_white;
  }
  .text {
    color: $white;
    font-weight: 500;
  }
  &:hover {
    background-color: lighten($primary_color, 50%);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  &.active {
    background-color: $white;
    color: $primary_color;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    transition: 0.3s ease-in-out;
    .link-image {
      filter: $filter_primary_color;
    }
    .text {
      color: $primary_color;
      font-weight: 600;
    }
    img {
      filter: $filter_primary_color;
    }
  }
  .arrow {
    transition: transform 0.3s ease-in-out;
    &.closed {
      height: 6px;
    }
  }
  &.open .arrow {
    transform: rotate(-90deg);
    transition: transform 0.3s ease-in-out;
  }
}
.pageName-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 2rem;
  &.closed {
    padding-right: 0rem;
  }
}
.link {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  height: 100%;
  padding-left: 0.5rem;
  width: 100%;
  &.closed {
    gap: 0.1rem;
    padding-left: 0.7rem;
    justify-content: center;
  }
  img {
    filter: $filter_white;
  }
}

.nav-children {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.nav-children.open {
  max-height: calc(100vh - 10vh);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $primary_color;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: max-height 0.3s ease-in-out;

  @include respond-to(lg) {
    background-color: transparent;
    max-height: calc(100vh - 50vh);
  }
}

.children-link {
  &.closed {
    gap: 0.1rem;
    padding-left: 0.7rem;
    justify-content: center;
  }
  @extend .nav-link;
  width: 100%;
  border-radius: 0px;
  box-shadow: none;
  height: 35px;
  font-size: var(--font-size-14);
  font-weight: 500;
  text-decoration: none;
  padding: 1rem 0rem;
  img {
    width: 26.687px;
    height: 18px;
  }
}

.fullWidth {
  width: 100% !important;
}

.parent-link {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 0.5rem;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  text-decoration: none;
  gap: 1rem;
  &.closed {
    justify-content: center;
    padding-left: 0rem;
    // margin-left: 0.2rem;
  }
  .link-image {
    filter: $filter_white;
    height: 23px;
  }

  &.active {
    background-color: $white;
    color: $primary_color;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    transition: 0.3s ease-in-out;

    .text {
      color: $primary_color;
      font-weight: 600;
    }
    img {
      filter: $filter_primary_color;
      height: 23px;
    }
  }
}
