@import '../../../../assets/styles/main';

.checkbox {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  cursor: pointer;
}

.checkbox-label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checkbox-input {
  display: none;
}

.checkbox-square {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #aaa;
  border-radius: 3px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  transition: background-color 0.2s, border-color 0.2s;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: $primary_color;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 2px;
    transition: transform 0.2s;
  }
}

.checkbox-input:checked + .checkbox-square {
  border-color: $primary_color;
}

.checkbox-input:checked + .checkbox-square:after {
  transform: translate(-50%, -50%) scale(1);
}

.label {
  font-size: var(--font-size-14);
  font-weight: 600;
  text-transform: capitalize;
  color: $primary_color;
}

.hover {
  cursor: pointer;
}

.checkboxDisabled {
  background-color: rgba(128, 128, 128, 0.089);
}
