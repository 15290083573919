* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
}

p {
  font-family: 'Raleway', sans-serif;
}

::-webkit-scrollbar {
  width: 7px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10rem;
  border: 1px solid #fff !important;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent !important;
}

.no-scroll {
  overflow: hidden !important;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
/* 
.slick-slider {
  padding-left: 1.7rem;
  padding-right: 1.7rem;
} */

.form-footer {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f8f8f802;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1b224c31;
  border-radius: 25px;
}
