@import '../../../../assets/styles/main';

.menu-container {
  position: fixed;
  top: 0;
  right: -130%;
  width: 60%;
  height: 100%;
  background-color: $white;
  color: white;
  transition: right 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2rem;
  box-shadow: none;
  z-index: 1000;
  overflow: auto;
  @include respond-to(xl) {
    width: 80%;
  }
  @include respond-to(sm) {
    width: 80%;
  }
  @include respond-to(xs) {
    width: 100%;
  }
  &.open {
    right: 0;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    h3 {
      color: black;
      text-transform: capitalize;
    }
    img {
      cursor: pointer;
    }
  }
}

.menu {
  max-height: 75vh;
  overflow: auto;
}

.button-container {
  margin: 2rem 0rem;
}

.slider-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  @include respond-to(lg) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.menu-button {
  background-color: red;
  padding: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  img {
    filter: $filter_white;
    height: 22px;
  }
}
