@import '../../../assets/styles/main';

.tag {
  border-radius: 27px;
  padding: 5px 10px;
  line-height: normal;
  vertical-align: middle;
  font-size: var(--font-size-10);
  font-weight: 600;
  color: $white;

  p {
    margin-top: 0px;
    text-transform: capitalize;
  }

  &.red {
    background-color: rgba(244, 67, 54, 0.5);
    color: red;
  }

  &.yellow {
    background-color: rgba(255, 179, 2, 0.12);
    color: #ffb202;
  }

  &.green {
    background-color: rgba(76, 175, 80, 0.5);
    color: #1e931f;
  }
  &.blue {
    background: #daebff;
    color: #0020e0;
  }
  &.grey {
    background-color: rgba(128, 128, 128, 0.265);
    color: rgb(128, 128, 128);
  }

  &.default {
    background-color: rgba(128, 128, 128, 0.265);
    color: rgb(128, 128, 128);
  }
}

.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  vertical-align: middle;
}

.redCircle {
  background-color: #f44336;
}

.yellowCircle {
  background-color: #ffb302;
}

.greenCircle {
  background-color: #4caf50;
}
.greyCircle {
  background-color: rgb(128, 128, 128);
}
.blueCircle {
  background-color: #0020e0;
}

.defaultCircle {
  background-color: black;
}
