@import '../../../assets/styles/main';

.page-title {
  color: $primary_color;
  font-size: var(--font-size-18);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.page-title--md {
  @extend .page-title;
  font-size: var(--font-size-20);
}
.page-title--sm {
  @extend .page-title;
  font-size: var(--font-size-14);
}
.page-title--lg {
  @extend .page-title;
  font-size: var(--font-size-24);
}
.page-title--sm-black {
  @extend .page-title;
  font-size: var(--font-size-14);
  font-weight: 500;
}
.page-title--sm-blue {
  @extend .page-title;
  font-size: var(--font-size-12);
  font-weight: 500;
}
.page-title--md-blue {
  @extend .page-title;
  font-size: var(--font-size-16);
  font-weight: 500;
}
.page-title--sm-blue-bold {
  @extend .page-title;
  font-size: var(--font-size-12);
  font-weight: bold;
}
.page-title--sm-bold-black {
  @extend .page-title;
  font-size: var(--font-size-14);
  font-weight: bold;
}

.hover {
  cursor: pointer;
  &:hover {
    color: red;
  }
}
