@import '../../../../assets/styles/main';

.checkbox {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  .checkbox-input {
    display: none;
  }

  .checkbox-checkmark {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 3px;
    border: 2px solid #aaa;
    background-color: #fff;
    transition: background-color 0.2s, border-color 0.2s;

    &:after {
      content: '';
      position: absolute;
      top: 45%;
      left: 2px;
      width: 2px;
      height: 7px;
      border: solid #fff;
      border-width: 0 0.2rem 0.2rem 0;
      transform: rotate(45deg) translate(-50%, -50%) scale(0);
      transition: transform 0.2s ease;
    }
  }

  .checkbox-input:checked + .checkbox-checkmark {
    background-color: red;
    border-color: red;
  }

  .checkbox-input:checked + .checkbox-checkmark:after {
    transform: rotate(45deg) translate(-50%, -50%) scale(1);
  }

  .label {
    font-weight: 700;
    font-size: var(--font-size-12);
    color: $primary_color;
  }
}
