@import '../../../../../assets/styles/main';
.requests {
  display: flex;
  gap: 1rem;
  @include respond-to(lg) {
    flex-direction: column;
  }
}
.title {
  font-size: var(--font-size-14);
  font-weight: 700;
  color: $primary_color;
}
.documents-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}
.title {
  font-size: var(--font-size-14);
  font-weight: 700;
  color: $primary_color;
}
.test {
  padding-bottom: 15px;
  border-bottom: solid 1px #cdcdcd;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.requests-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: $primary_color;
}

.requests > * {
  flex: 1;
}
.detailsCont {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  padding-bottom: 0.5rem;
}
.detailsCont img {
  height: 35px;
}
.detailsSubCont,
.application {
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
}
.withdrawal-reasons {
  display: flex;
  flex-direction: column;
}
.documents {
  display: flex;
  flex: 1;
  gap: 2rem;
}

.appeal-container {
  display: flex;
  gap: 5vw;

  .title {
    font-size: var(--font-size-14);
  }
  .text {
    font-size: var(--font-size-14);
    color: $primary_color;
  }
}
.error-message {
  color: red;
  font-size: var(--font-size-12);
  padding-top: 1rem;
}
.moreOptions_cont {
  display: flex;
  justify-content: baseline;
  align-items: center;
  gap: 10%;
  @include respond-to(sm) {
    gap: 0.5rem;
    flex-direction: column;
  }
}

.table {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.customizedTitle_cont {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.customizedTitle {
  color: $primary_color;
  p {
    font-size: var(--font-size-14);
    font-weight: 600;
  }
  span {
    font-size: var(--font-size-14);
    font-weight: 500;
  }
}

.attention {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #fff2d5;
  padding: 0.8rem;
  border-radius: 40px;
  p {
    font-weight: 600;
    font-size: var(--font-size-14);
  }
  img {
    height: 18px;
  }
}
.selects {
  display: flex;
  gap: 1rem;
}
.decision {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}
.button-container {
  display: flex;
  justify-content: space-between;
}

.logs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text {
    font-size: var(--font-size-18);
    font-weight: 600;
  }
}
