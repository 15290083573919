.toggle-container {
  display: flex;

  align-items: center;
  gap: 0.5rem;
  user-select: none;
}

.toggle-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.toggle {
  background-color: transparent;
  border-radius: 20px;
  position: relative;
  transition: background-color 0.3s ease;
  height: 20px;
  width: 32px;
  border: 1px solid #ccc;
}

.toggle.inOut {
  height: 40px;
  width: 70px;
}
.toggle-on {
  border: 1px solid red;
}

.toggle-off .toggle-handle {
  background-color: #ccc;
}
.toggle-handle {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 1px;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: var(--font-size-12);
  text-transform: capitalize;
  span {
    color: red;
  }
}
.toggle.inOut .toggle-handle {
  width: 35px;
  height: 35px;
  top: 2px;
  left: 0.5px;
}
.toggle-on .toggle-handle {
  transform: translateX(17px);

  span {
    color: white;
  }
}
.toggle.inOut.toggle-on .toggle-handle {
  transform: translateX(32px);

  span {
    color: white;
  }
}
.toggle-error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: var(--font-size-16);
  margin-top: 0.5rem;
}

.in {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  font-size: var(--font-size-12);
  font-weight: 700;
  transition: background-color 0.3s ease;
}
.out {
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 0.7rem;
  justify-content: flex-end;
  font-size: var(--font-size-12);
  font-weight: 700;
  transition: background-color 0.3s ease;
}
