@import '../../../assets/styles/main';

.login-page {
  display: flex;
  align-items: center;
  height: 100vh;
  margin: auto;
  background-color: white;
}

.form-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  border-radius: 1rem;
  // background-color: $secondary_color;
  margin: auto;
  @include respond-to(sm) {
    width: 90%;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3vh 0rem;
    img {
      width: 70%;
      filter: brightness(0) saturate(100%) invert(29%) sepia(12%)
        saturate(5458%) hue-rotate(185deg) brightness(92%) contrast(89%);
    }
  }
  .text {
    font-size: var(--font-size-16);
    font-weight: 600;
    color: $primary_color;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
    &:hover {
      transition: 0.3s ease-in-out;
      text-decoration: underline;
    }
  }
}
.footer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.login-video {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @include respond-to(lg) {
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}

.video {
  width: 97%;
  height: 95vh;
  margin: auto;
  border-radius: 30px;
  overflow: hidden;
  @include respond-to(lg) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.button {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  min-height: 4rem;
}
