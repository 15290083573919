.modal {
  width: clamp(300px, 55vw, 900px);
  max-width: 900px;
}

.course-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
