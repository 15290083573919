@import '../../../assets/styles/main';

.profile-dropdown {
  position: relative;
  display: flex;
  justify-content: baseline;
  background-color: $white;
  height: min-content;
  gap: 0.4rem;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  border-radius: 50px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  @include respond-to(lg) {
    border-radius: 50%;
    padding: 0.5rem;
  }
  cursor: pointer;
  .arrow {
    width: 0.8rem;
    filter: $disabledFilter;
  }
}

.profile-image-container {
  border-radius: 50%;
  overflow: hidden;
  // border: 3px solid rgba(0, 0, 0, 0.288);
  height: 3rem;

  img {
    // width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.info {
  display: flex;
  min-width: 6rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  @include respond-to(lg) {
    display: none;
  }
  .name {
    font-size: var(--font-size-14);
    font-weight: 700;
    color: $primary_color;
    margin-bottom: 0.3rem;
  }
  .type {
    font-size: var(--font-size-12);
    color: $disabled;
    font-weight: 600;
  }
}
.dropdown-menu {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  background-color: white;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 18px;
  &.entering {
    opacity: 1;
    transform: translateY(0);
  }

  &.exiting {
    opacity: 0;
    transform: translateY(-10px);
  }
  @include respond-to(lg) {
    width: 130px;
    left: -80px;
  }
  .button {
    color: $primary_color;
    cursor: 'pointer';
    font-size: var(--font-size-16);
    padding: 1rem;
    font-weight: 600;
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    &:hover {
      background-color: whitesmoke;
      border-radius: 18px;
    }
  }
}
