@import '../../../../assets/styles/main.scss';
.email-tab-container {
  display: flex;
}
.email-nav {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-right: 1px solid rgba(32, 32, 32, 0.261);
  padding-right: 1.5rem;
}
.email-content-diplay {
  flex: 4;
  margin-left: 1.5rem;
}
.header {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.header-buttons {
  display: flex;
  gap: 1rem;
  background-color: #e9f3ff;
  width: 100%;
  padding: 0.5rem;
  border-radius: 12px;

  .button {
    background-color: transparent !important;
    color: black;
    font-weight: 600;
    flex: 1;
    img {
      filter: $filter_black;
    }
    &:hover {
      background-color: red !important;
      color: white !important;
      border-color: red !important;
      img {
        filter: $filter_white !important;
      }
    }
  }
}
.emails-bar {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.round-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  padding: 0.7rem;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;

  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    background-color: rgb(211, 0, 0);

    img {
      transform: rotate(45deg);
    }
  }
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  text-transform: capitalize;

  .headings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .title {
      font-size: var(--font-size-24);
      font-weight: 600;
    }
  }
  .date {
    display: flex;
    align-items: center;
    gap: 0.2rem;

    .date-title {
      font-size: var(--font-size-14);
      font-weight: 600;
    }
  }
}
