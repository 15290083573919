@import '../../../assets/styles/main';

.application-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0px 2px 6px 0px #2e2e2e40;
  padding: 1.5rem;
  border-radius: 20px;
  flex: 1;
  background-color: white;
  max-width: 350px;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.073);
    z-index: 10;
    border-radius: inherit;
  }
}

.image-container {
  background-color: #ecf4ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  height: 10rem;

  img {
    width: max-content;
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
  .subContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .desc {
    width: 90%;
    color: var(--Color-blue-blue-80, #494e70);
    font-weight: 400;
    font-style: normal;
    font-size: var(--font-size-16);
  }

  .title {
    color: $black;
    font-size: var(--font-size-20);
  }

  .link {
    margin-top: auto;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    background-color: transparent;

    p {
      font-size: var(--font-size-18);
      color: red;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 600;
      text-transform: capitalize;
    }

    &.disabled {
      pointer-events: none;
    }

    .disabled-text {
      color: gray;
      cursor: not-allowed;
    }
  }
}

.application-modal {
  max-width: 950px !important;
  .terms-content {
    ul,ol {
      margin: 1em 0;
      padding-left: 2em;
    }

    li {
      margin-bottom: 0.5em;
    }

  }
}
