@import '../../../../assets/styles/main';
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  @include respond-to(sm) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}

.left {
  width: 30%;
  @include respond-to(sm) {
    width: 100%;
  }
}

.right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  @include respond-to(sm) {
    width: 100%;
    justify-content: flex-end;
  }
}

button {
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-image {
  width: 1.3rem;
}
