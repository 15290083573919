.slider-wrapper {
  display: flex;
  align-items: center;
}

.slider-container {
  width: 100%;
  overflow: hidden;

  .slick-list {
    overflow: hidden;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .slick-track {
    display: flex;
  }
}

.card {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 0.5rem;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;

  color: #fff;
  border-radius: 20%;
  cursor: pointer;
  z-index: 2;
}

.next {
  right: -0px;
}

.prev {
  left: -0px;

  img {
    transform: rotate(180deg);
  }
}
