@import '../../../assets/styles/main';

.edu-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.edu-item img {
  width: 20px;
  height: 11.964px;
}
.text {
  color: $primary_color;
  font-size: var(--font-size-14);
  font-weight: 500;
  font-style: normal;
}

.title {
  color: $primary_color;
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.active {
  color: $active;
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: $activeHover;
  padding: 5px 10px;
  border-radius: 10px;
  text-transform: capitalize;
}
