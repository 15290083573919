@import '../../../assets/styles/main';
.tabCont {
  display: flex;
  align-items: center;
  gap: 45px;
}
.tabs {
  width: 100%;
}
.container {
  padding: 0.8rem 1.5rem;
  background-color: $white;
  border-radius: 10px;
  @include respond-to(md) {
    padding: 1rem 1rem;
  }
}
.tab-buttons {
  position: relative;
  display: flex;
  gap: 45px;
  text-transform: capitalize;
  overflow-x: auto;
  @include respond-to(sm) {
    white-space: nowrap;
    overflow-x: auto;
    gap: 18px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
  .tabHead {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    // width: max-content;
    // min-width: 12rem;
    padding: 0.8rem 0.6rem 0.8rem 0rem;
    cursor: pointer;
    border: none;
    position: relative;
    color: $disabled;
    font-size: var(--font-size-16);
    font-weight: 600;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 50%;
      width: 0;
      height: 2px;
      background-color: red;
      transition: width 0.3s ease, right 0.3s ease;
    }
    .enum {
      color: $white;
      width: 20px;
      height: 20px;
    }
    &.active {
      color: red;

      &:after {
        width: 100%;
        right: 0;
      }
      img {
        filter: $filterRed;
        max-width: 30px;
      }
      .link {
        color: red;
        font-size: 18px;
      }
      .enum {
        // color: $white;
        // width: 20px;
        // height: 20px;

        &.active {
          background-color: red;
        }
      }
    }
    img {
      max-width: 30px;
      @include respond-to(sm) {
        height: 18px;
      }
    }
  }
}

.tab-content {
  padding: 20px;
  border-top: none;
}

.tab {
  &.active {
    display: block;
  }
  &:not(.active) {
    display: none;
  }
}
.link {
  text-decoration: none;
  text-align: center;
  padding-top: 5px;
  color: $disabled;
  font-size: 18px;
}

.enum {
  position: relative;
  box-sizing: initial;
  background-color: $disabled;
  border-radius: 50%;
  padding: 4px 3px;
  background-color: gray;
  span {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-14);
  }
}

.removePadding {
  padding: 0;
  margin-top: 2vh;
}
