.profile-tag {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  .title {
    font-size: var(--font-size-18);
    font-weight: 600;
  }
  .role {
    font-size: var(--font-size-12);
    font-weight: 600;
  }
}
.profile {
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
  }
}
