.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchInput {
  width: clamp(200px, 20vw, 600px);
  margin: 1rem 0rem;
}

.terms-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
