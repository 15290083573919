@import '../../../../../../../assets/styles/main';

.emailListCont {
  background-color: white;
  border-radius: 10px;
  width: 350px;
  .received_List {
    position: relative;
    &.active {
      border-radius: 10px;
      background-color: #fafafc;
    }
    .leftBorder {
      border-left: 8px solid #fff;
      padding: 10px;
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      transition: 0.1s ease-in-out;
      &.activeLeftBorder {
        border-left: 8px solid #f3223c;
      }
    }
    .List {
      padding: 15px 21px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-bottom: 1px solid #f2f3f4;
      cursor: pointer;
      h2 {
        color: #1b224c;
        font-size: var(--font-size-18);
        font-weight: 700;
      }
      h3 {
        max-width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #1b224c;
        font-size: var(--font-size-14);
        font-weight: 400;
        opacity: 0.8;
      }
    }
  }
}
.email_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 23px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  img {
    width: 45px;
  }
  h3 {
    color: #000;
    font-size: var(--font-size-14);
    font-weight: 600;
  }
  h4 {
    color: #000;
    font-size: var(--font-size-14);
    font-weight: 600;
    padding-bottom: 4px;
    span {
      font-size: var(--font-size-16);
    }
  }
  .header_subContainer {
    justify-content: space-between;
    border-bottom: 2px solid #dadada;
    padding-bottom: 1rem;
  }
  .email_header_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    p {
      color: #1b224c;
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .subContainer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  .content-email {
    padding-bottom: 10px;
  }
}
