.toggle-container {
  display: flex;

  align-items: center;
  gap: 0.5rem;
  user-select: none;
}

.toggle-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.toggle {
  width: 30px;
  height: 21px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  transition: background-color 0.3s ease;
  height: 20px;
  width: 40px;
  border: 1px solid #ccc;
}

.toggle-on {
  border: 1px solid red;
  background-color: white;
}

.toggle-off {
  background-color: #ccc;
}
.toggle-off .toggle-handle {
  background-color: white;
}
.toggle-handle {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 5px;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: var(--font-size-12);
  text-transform: capitalize;
  span {
    color: red;
  }
}
.label {
  font-weight: 700;
  font-size: var(--font-size-16);
}
.toggle-on .toggle-handle {
  transform: translateX(19px);
  span {
    color: white;
  }
}

.toggle-error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
