@import '../../../../../assets/styles/main';

.request-cont {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  .date-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: var(--font-size-14);
      color: $primary_color;
      font-weight: 600;
      span {
        font-weight: 500;
        color: $primary_color;
      }
    }
  }
  .request-detail {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .request-subcont {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    color: $primary_color;
    p {
      font-size: var(--font-size-14);
      font-weight: 500;
    }
    img {
      height: 18px;
    }
  }
  .documents-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .accordion_color {
    background-color: #f5f9ff !important;
  }
  .detailsCont {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding-bottom: 0.5rem;
    img {
      height: 35px;
    }
  }
  .customizedTitle_cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }
  .customizedTitle {
    color: $primary_color;
    p {
      font-size: var(--font-size-14);
      font-weight: 600;
    }
    span {
      font-size: var(--font-size-14);
      font-weight: 500;
    }
  }
}
