@import '../../../../../assets/styles/main';

.select-container {
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  .labelCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      height: 14px;
      cursor: pointer;
    }
  }
  .select-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    border: 1px solid $primary_color;
    cursor: pointer;
    .svg {
      width: 20px;
    }
    &:focus {
      outline: none;
      border-color: darken($primary_color, 10%);
    }
    .select-display {
      display: flex;
    }

    .select {
      outline: none;
      border: none;
      width: 100%;
      color: $placeholder;
      background-color: $white;
      padding: 0.5rem;
      cursor: pointer;
      background-color: transparent;
      text-transform: capitalize;
      font-size: var(--font-size-14);
      &.has-value {
        color: black;
      }
    }
    .arrow {
      margin-right: 5px;
      width: 15px;
    }
  }
}
.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: $white;
  max-height: 20vh;
  overflow-y: auto;
  z-index: 9999;
  margin-top: 0.1rem;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  &.dropdown-animation {
    opacity: 1;
    transform: translateY(0);
  }
  .option {
    padding: 0.8rem;
    background-color: $white;
    color: $primary_color;
    cursor: pointer;
    z-index: 999;

    &:nth-child(odd) {
      background-color: #f4f4f4;
    }

    &:nth-child(even) {
      background-color: white;
    }
    &.selected {
      background-color: lighten($primary_color, 50%);
    }
    &:hover {
      background-color: lighten($disabled, 10%);
    }
  }
  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background: $white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary_color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken($primary_color, 20%);
  }
}
.error-message {
  font-size: var(--font-size-12);
  color: red;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-transform: capitalize;
  font-size: var(--font-size-16);
  font-weight: 600;
  color: $primary_color;
}
.disable-scroll {
  overflow: hidden !important;
}

.disabled {
  background-color: rgba(128, 128, 128, 0.159);
  outline: none;
  border: 1px solid black;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionImg {
  height: 15px;
}

.actionImg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
