.course-actions {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .danger {
    font-size: var(--font-size-16);
    font-weight: 600;
    color: green;
  }
  .success {
    font-size: var(--font-size-16);
    font-weight: 600;
    color: red;
  }
}
.reason-container {
  display: flex;

  & > * {
    flex: 1;
  }
}
