@import '../../../../../assets/styles/main';

.search-input-container {
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  .input-wrapper {
    position: relative;
    display: flex;
    gap: 0.5rem;
    padding: 0.8rem 0.5rem 0.8rem 0.8rem;
    border-radius: 2rem;
    border: 2px solid $primary_color;
    align-items: flex-end;
    .input {
      outline: none;
      border: none;
      width: 100%;
      color: $primary_color;
      background-color: transparent;
      font-size: var(--font-size-18);
      text-transform: capitalize;

      &:focus {
        border-color: $primary_color;
      }

      &::placeholder {
        border-color: $primary_color;
        color: $primary_color;
      }
    }

    .placeholder {
      position: absolute;
      bottom: 5px;
    }
  }
  .password-toggle {
    position: absolute;
    width: 30px;
    height: 90%;
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    top: 0px;
    cursor: pointer;
    background-color: white;
  }
}
