@import '../../../../../../assets/styles/main';

.reasons-container {
  // flex: 1;
  background-color: white;
  width: 100%;
  @include respond-to(md) {
    width: 80%;
  }
  @include respond-to(xs) {
    width: 80%;
  }
}
