@import '../../../../assets/styles/main';

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.radio-label {
  font-size: var(--font-size-16);
  color: $primary_color;
}
.options-wrapper {
  display: flex;
  gap: 2rem;
}
.error-message {
  color: red;
  font-size: var(--font-size-12);
}
.radio-button {
  display: flex;
  align-items: center;

  input[type='radio'] {
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid $primary_color;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    margin-right: 0.5rem;
    position: relative;

    &:checked {
      background-color: $white;
      border-color: $primary_color;
    }

    &:checked::before {
      content: '';
      display: block;
      width: 17px;
      height: 17px;
      background-color: $primary_color;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  label {
    font-weight: 600;
    font-size: var(--font-size-14);
    cursor: pointer;
  }
}
