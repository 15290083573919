@import '../../../../../../assets/styles/main.scss';
.cont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.warning-img-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $primary_color;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inner-section {
  display: flex;
  gap: 1rem;
}
