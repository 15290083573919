$primary_color: #1b224c;
$primary_color_hover: #1b224c48;
$colors-surface-secondary: #f3f8ff;
$danger_color: red;
$danger_color_hover: #f3223ae4;
$placeholder: #767a94;
$secondary_color: #ffffff;
$verify_color: #0167b1;

$white: white;
$black: black;

$disabled: #767a94;
$disabledFilter: brightness(0) saturate(100%) invert(53%) sepia(8%)
  saturate(1042%) hue-rotate(195deg) brightness(89%) contrast(84%);

$active: #1e931f;
$activeHover: #d3ffd3;
$inactive: '';
$filterRed: brightness(0) saturate(100%) invert(17%) sepia(89%) saturate(5228%)
  hue-rotate(356deg) brightness(94%) contrast(122%);
$filter_primary_color: brightness(0) saturate(100%) invert(15%) sepia(6%)
  saturate(7005%) hue-rotate(196deg) brightness(91%) contrast(100%);
$filter_white: brightness(0) saturate(100%) invert(98%) sepia(98%) saturate(0%)
  hue-rotate(350deg) brightness(104%) contrast(102%);
$filter_black: brightness(0) saturate(100%);
$filterBlue: brightness(0) saturate(100%) invert(21%) sepia(98%) saturate(1972%)
  hue-rotate(189deg) brightness(99%) contrast(99%);
$filterGreen: brightness(0) saturate(100%) invert(42%) sepia(82%) saturate(552%)
  hue-rotate(73deg) brightness(88%) contrast(90%);
