@import '../../../../assets/styles/main';

.app-body {
  display: flex;
  height: 100vh;
  max-width: 2500px;
  margin: auto;
}

.layout {
  flex-grow: 1;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  @include respond-to(lg) {
    display: none;
  }
}

.page {
  flex-grow: 1;
  padding: 3vh 2rem;
  @include respond-to(sm) {
    padding: 1vh 0.3rem;
  }
}
.mobile-layout {
  display: none;
  @include respond-to(lg) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100dvh;
  }
}
