@import '../../../../assets/styles/main';

.students-header {
  width: 100%;
  background-color: #e0edfd;
  padding: 1.5rem 0 0 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: $header_zindex;
  @include respond-to(lg) {
    display: none;
  }
  .title {
    font-size: var(--font-size-18);
    color: $primary_color;
    font-weight: 500;
  }
}

.header {
  width: 80vw;
  margin: auto;
}

.logo {
  display: flex;
  gap: 2rem;
  align-items: center;
  @include respond-to(md) {
    flex-direction: column;
  }
  .aud-logo {
    width: clamp(150px, 12vw, 270px);
    cursor: pointer;
  }
  .searchCont {
    flex: 1;
  }
}
.top-side {
  display: flex;
  justify-content: space-between;
}
.left-side {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.right-side {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -25px;
  @include respond-to(sm) {
    display: none;
    margin-bottom: 0px;
  }
}
