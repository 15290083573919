@import '../../../assets/styles/main';

.sidebar-container {
  background-color: $white;
  z-index: 999;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 100vh;
  @include respond-to(lg) {
    display: none;
  }
}

.sidebar {
  position: relative;
  width: 110px;
  height: 100%;
  background-color: $primary_color;
  color: $white;
  transition: width 0.3s ease-in-out;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 2;
  .text {
    display: none;
  }
  &.open {
    width: clamp(220px, 15vw, 270px);

    &.flesh {
      transform: rotate(360deg);
    }
    .text {
      display: block;
    }
  }

  .logo {
   position: relative;
    /* height: 130px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: clamp(15px, 4vw, 20px);
    padding-left: 10px;
    padding-top: 55px;
    img {
      cursor: pointer;
      width: 80%;
      filter: brightness(0) saturate(100%) invert(95%) sepia(5%) saturate(0%)
        hue-rotate(5deg) brightness(103%) contrast(107%);
    }
  }
}

.toggleButton {
  position: absolute;
  right: -13px;
  z-index: 20;
  border: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  top: 50px;
  display: flex;
  z-index: 101;
  transition: 0.3s all ease;

  img {
    width: 25px;
  }
  .flesh {
    position: absolute;
    top: 6px;
    left: 8px;
    transition: transform 0.3s ease-in-out;
    z-index: 999;

    width: 7px;
  }
}
.toggle-button-rotate {
  transform: rotate(180deg) !important;
  transition: 0.3s all ease;
}
.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
  align-items: flex-end;
  transition: transform 0.3s ease-in-out;
  overflow: auto;
  max-height: 85vh;
  overflow-x: hidden;
  padding-top: 0.5rem;
  @include respond-to(xxl) {
    max-height: 80vh;
  }
  @include respond-to(xl) {
    max-height: 70vh;
  }
}

.aud-logo-small {
  width: 70px !important;
}
