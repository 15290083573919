@import '../../../assets/styles/main.scss';
.offline-page {
  width: 100vw;
  height: 100vh;
  background-color: #03eeff46;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  h1 {
    font-weight: 700;
    color: $primary_color;
  }

  p {
    font-size: var(--font-size-28);
  }
}
