@import '../../../../../assets/styles/main.scss';

.formCont {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include respond-to(md) {
    width: 100%;
  }
}
