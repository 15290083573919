@import '../../../assets/styles/main';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $modal-Zindex;
}

.modalContent {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  position: relative;
  width: clamp(330px, 30vw, 500px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.title {
  font-weight: 700;
  font-size: var(--font-size-26);
  color: $primary_color;
  text-align: center;
}

.textCont{
  display: flex;
  flex-direction: column;
  gap:10px;
  align-items: center;
  justify-content: center;
}
.text {
  font-weight: 400;
  font-size: var(--font-size-18);
  color: $primary_color;
}
.subText {
  font-weight: 400;
  font-size: var(--font-size-14);
  color: $primary_color;
}
/* Transition classes */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
