@import '../../../../../../assets/styles/main';
.back-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .items {
    display: flex;
    gap: 1rem;
    color: $primary_color;
    img {
      cursor: pointer;
    }
  }
}

.tabs-container {
  margin-top: 1.5rem;
}
