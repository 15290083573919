@import '../../../../../assets/styles/main.scss';

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-container {
  display: flex;
  gap: 1rem;
  @include respond-to(sm) {
    flex-direction: column;
  }
}

.round-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  padding: 0.7rem;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;

  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    background-color: rgb(211, 0, 0);

    img {
      transform: rotate(45deg);
    }
  }
}

.modal {
  width: clamp(300px, 70vw, 2000px);
  max-width: 1200px;
  @include respond-to(sm) {
    width: clamp(300px, 90vw, 2000px);
  }
}
.inputs-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 1rem;
  @include respond-to(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}
