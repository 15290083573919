@import '../../../../../../../assets/styles/main';

.requests-container {
  display: flex;
  gap: 0.5rem;
  @include respond-to(sm) {
    flex-direction: column;
  }
}
.forms-layout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.requests-container > * {
  flex: 1;
}

.nth-table {
  width: max-content !important;
  th {
    padding-right: 3rem !important;
  }
}
.text {
  font-size: var(--font-size-18);
  font-weight: 600;
  text-transform: capitalize;
  color: $primary_color;
}

.accordion-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .title {
    margin-top: 0.5rem;
    font-size: var(--font-size-18);
    font-weight: 600;
    color: $primary_color;
    text-transform: capitalize;
  }
  .desc {
    font-size: var(--font-size-14);
    color: $primary_color;
    text-transform: capitalize;
  }
  .date {
    font-size: var(--font-size-14);
    color: $primary_color;
  }
}

.accordions-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
