@import '../../../assets/styles/main';

.paginator-container {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 1rem;
  gap: 0.25rem;
  user-select: none;
}

.paginator {
  display: flex;
  gap: 0.25rem;
}

.page-item,
.page-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  font-size: var(--font-size-12);
  font-weight: 600;
  color: #a6b8d2;
  background-color: $white;
  border: 1px solid #d1d5dbd6;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  padding: 1rem;
  &:hover {
    background-color: #ecf4ff;
    color: $primary_color;
  }

  &.active {
    background-color: red;
    color: $white;
  }

  &.disabled {
    cursor: not-allowed;
    color: #9ca3af;
    border-color: #d1d5db;
  }
}

.page-arrow {
  color: #1b224c;
  background-color: #1b224c;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;

  &:hover:not(.disabled) {
    background-color: #1b224ca2;
    color: $primary_color;
  }

  &.disabled {
    cursor: not-allowed;
    color: #9ca3af;
    border-color: #d1d5db;
    background: #a6b8d2;
  }
}

.arrow-left {
  transform: rotate(180deg);
}
.ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 2rem;
  height: 2rem;
  color: #a6b8d2;

  span {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #a6b8d2;
    border-radius: 50%;
    transition: transform 0.3s;
  }

  &:hover span {
    transform: scale(1.2);
  }
}
