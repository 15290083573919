@import '../../../assets/styles/main.scss';

.navbarContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 80px;
  height: 80vh;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.029);
  transition: width 0.3s;

  &.open {
    width: 220px;
    .listItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .listItem {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.3s;
  }
}

.attributes {
  padding: 0.8rem;
  background-color: #1b224c;
  font-size: var(--font-size-16);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  font-weight: 600;
  gap: 1rem;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: flex-start;
  &.open {
    justify-content: flex-start;
  }
  &.centered {
    justify-content: center;
  }
}

.ulCont {
  list-style: none;
  padding: 0;
  margin: 0;
  p {
    text-transform: capitalize;
    white-space: nowrap;
  }
  //   overflow-y: auto;
}

.listItem {
  padding: 0.8rem 1rem;
  background-color: #fafafc;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: background-color 0.3s;
  text-decoration: none;
  font-size: var(--font-size-14);
  color: $primary_color;
  img {
    filter: $filter_primary_color;
    width: 50px;
  }
  &.even {
    background-color: white;
  }
}

.activeListItem {
  color: #f3223c;
  background-color: #e7ebf0;
  img {
    filter: $filterRed;
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.listItem:hover {
  background-color: #e7ebf0;
}

.fleish {
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}

.fleishRotate {
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
}
.visible {
  display: flex;
  font-weight: 600;
}

.invisible {
  display: none;
}
.centered {
  transition: 0.3s ease-in-out;

  justify-content: center;
}
