.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
