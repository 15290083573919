@import '../../../../../../../assets/styles/main.scss';

.withdrawal-modal {
  p {
    font-size: var(--font-size-14);
    margin-bottom: 20px;
    line-height: 1.5;
    color: $primary_color;
  }

  .refund-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;

    th {
      border: 1px solid black;
      text-align: left;
      padding: 0.8rem;
      font-weight: 700;
      font-size: var(--font-size-18);
    }

    td {
      padding: 0.8rem;
      border: 1px solid black;
      font-weight: 600;
      font-size: var(--font-size-14);
    }
  }
}
