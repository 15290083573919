@import '../../../../../../assets/styles/main.scss';
.gradeCont {
  display: flex;
  gap: 3rem;
  align-items: center;
  padding-bottom: 1rem;
  .gradeSubCont {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: start;
    h4 {
      font-size: var(--font-size-14);
      color: $danger_color;
      font-weight: 600;
    }
    p {
      color: $danger_color;
      font-weight: 500;
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .decision {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    p {
      font-size: var(--font-size-14);
      color: $primary_color;
      font-weight: 600;
      padding-top: 20px;
    }
  }
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  .selects {
    display: flex;
    gap: 1rem;
  }
  .customizedTitle_cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }
  .customizedTitle {
    color: $primary_color;
    padding-top: 1rem;
    p {
      font-size: var(--font-size-14);
      font-weight: 600;
    }
    span {
      font-size: var(--font-size-14);
      font-weight: 500;
    }
  }
}
