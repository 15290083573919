@import '../../../../assets/styles/main';

.privilage-container {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.7rem;
  background-color: #fafafc;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken(#fafafc, 1%);
  }

  img {
    filter: $filter_primary_color;
    transition: transform 0.3s ease;
    transform: rotate(0deg);
  }

  &.open img {
    transform: rotate(180deg);
  }
}
.privilages {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0.4rem;
  opacity: 1;
}

.privilage {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}

.label {
  font-size: var(--font-size-18);
  font-weight: 600;
  text-transform: capitalize;
  color: $primary_color;
}

.dropdown-content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  overflow: auto;
  transform: translateY(-10px);
  transition: max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
  max-height: 60vh;
  margin-left: 1rem;
  @include respond-to(lg) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.dropdown-item {
  padding: 0.5rem;
  background-color: #fff;
  // border-bottom: 1px solid #ddd;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  background-color: #fafafc;
  align-self: start;
  border-radius: 10px;
  &:last-child {
    border-bottom: none;
  }

  .text {
    font-size: var(--font-size-18);
    color: $primary_color;
    font-weight: 700;
  }
}
.child-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.child-actions-cont {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  background-color: #fafafc;
  padding: 0.5rem;
  @include respond-to(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grouped-action-container {
  background-color: #e9e9ee86;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;
}
