@import '../../../../../assets/styles/main';

.email-container {
  width: clamp(300px, 40vw, 1000px);
  @include respond-to(xl) {
    width: 100%;
  }
}

.input-container {
  display: flex;
  gap: 1rem;
  @include respond-to(sm) {
    flex-direction: column;
  }
}

.title {
  color: $primary_color;
  font-weight: 700;
  text-transform: capitalize;
  font-size: var(--font-size-20);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container-class {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
