@import '../../../../assets/styles/colors';

.app-body {
  display: flex;
  flex-direction: column;
  background-color: $colors-surface-secondary;
  max-width: 2900px;
  margin: auto;

  .page {
    padding: 1rem 0 4rem 0;
  }
  .layout {
    width: 100%;
    min-height: 100vh;
  }
}
