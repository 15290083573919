@import '../../../../assets/styles//main.scss';
.all_applications_cont {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .search_cont div {
    width: 50em;
    @include respond-to(sm) {
      width: 90%;
    }
  }
}
.all-applications {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 0rem 0.5rem;
  // grid-template-columns: repeat(3, 1fr);
  @include respond-to(lg) {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }
}

.loader-container {
  width: 100%;
  height: 50dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}
