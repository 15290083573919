.Toastify__toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1100;
}

.Toastify__toast {
  /* padding: 10px 20px; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 0.5s, top 0.5s;
  background: #c4f5c8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  padding: 1rem 2rem;
  width: fit-content;
}
.taost-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.taost-container-arab {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: row-reverse;
}

.taost-container img,
.taost-container-arab img {
  filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(7012%)
    hue-rotate(355deg) brightness(107%) contrast(112%);
  width: 20px;
}
.Toastify__toast--hide {
  opacity: 0;
  top: 10px;
}
.Toastify__close-button {
  display: none;
}
:root {
  --toastify-color-success: #57d161;
  --toastify-color-error: #e90f07;
}
.Toastify__toast-theme--colored.Toastify__toast--error,
.Toastify__toast-theme--colored.Toastify__toast--success {
  width: auto;
}
