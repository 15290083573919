.box {
  border: 1px solid #1b224c6a;
  padding: 10px;
  border-radius: 10px;
  max-width: fit-content;
  .inner {
    display: flex;
    gap: 0.3rem;
    align-items: center;
  }
}
