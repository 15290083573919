@import '../../../assets/styles/main';
// .table-layout {
//   display: flex;
//   flex-direction: column;
//   gap: 0.2rem;
// }

.table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: $white;
  border-radius: 10px;
  overflow: hidden;
}

.table-container {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 66vh);
  max-height: calc(100vh - 56vh);
  overflow: auto;
}
.max-height-no-scroll {
  max-height: 5000px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.table-container th,
.table-container td {
  padding: 1.5rem 1rem;
  white-space: nowrap;
}

.table-container td {
  cursor: pointer;
  p {
    white-space: normal;
    text-align: left;
    word-break: break-word;
    // min-width: 15rem;
    @include respond-to(xl) {
      min-width: 10rem;
    }
  }
}

.container {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
}
.table-container th {
  white-space: normal;
  // word-break: break-word;
  text-align: left;
  min-width: 200px;
}
.table-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-container thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-container tbody tr {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-container tbody tr {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-container thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-container thead th {
  color: #1b224c;
  font-family: Raleway, sans-serif;
  font-size: var(--font-size-16);
  font-weight: 700;
  padding: 1.5rem 1rem;
  background: #ecf4ff;
}

.table-container tbody tr:nth-child(even) {
  background-color: #fafafc;
}

.table-container tbody td {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1b224c;
  font-size: var(--font-size-14);
  font-weight: 500;
}

.table-container tbody tr {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-container tbody tr:hover {
  background-color: #e7ebf0;
}

.tr-checkbox {
  border-radius: 15px 0 0 15px;
  position: relative;
}

.cell {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.action-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  padding-right: 3rem !important;
  max-width: 200px;
  float: right;

  img {
    width: 1.1rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: 0.3s all ease-in-out;
    }
  }
}

.appeal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;

  img {
    filter: $filterRed;
  }

  p {
    font-size: var(--font-size-14);
    font-weight: 600;
    color: red;
    text-transform: capitalize;
  }
}

.action-container-appeal {
  justify-content: flex-start;
}
.no-data {
  width: 100%;
  height: calc(100vh - 72vh);
  text-align: center;
  background-color: white;

  &:hover {
    cursor: default;
    background-color: white;
  }
  p {
    font-size: var(--font-size-24);
    text-transform: capitalize;
    font-weight: 700;
    text-align: center !important;
  }
}
.no-data-text {
  display: flex;
  flex-direction: column;
}
.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: default;

  p {
    font-size: var(--font-size-24);
    text-transform: capitalize;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.hidden-overflow {
  overflow: hidden;
}
.oos {
  overflow: hidden;
}
.selected-row {
  background-color: #6b7bd73a;
}

.sub-select {
  width: clamp(250px, 10vw, 500px);
}
.filters-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
