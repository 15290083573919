@import '../../../../../assets/styles/main';

.approved-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .header {
    position: relative;
    width: 100%;
  }
}

.modal {
  width: clamp(300px, 38vw, 1000px);
  max-width: 1000px;
  @include respond-to(lg) {
    width: clamp(300px, 70vw, 1000px);
  }
  @include respond-to(sm) {
    width: clamp(300px, 95vw, 1000px);
  }
}

.table {
  max-height: calc(100vh - 30vh) !important;
}
