@import '../../../assets/styles/main.scss';

.container {
  padding: 1rem 1.5rem;
  background-color: $white;
  border-radius: 10px;
  @include respond-to(md) {
    padding: 1rem 1rem;
  }
}
