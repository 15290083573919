@import '../../../../assets/styles/main';

.cont {
  border-bottom: 1px solid rgba(137, 137, 137, 0.498);
  padding: 0.5rem 0;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem 0;
  @include respond-to(xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.buttons-wrapper {
  display: flex;
  gap: 1vw;
}

.file-name {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid $primary_color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  font-weight: 600;
  width: max-content;
  max-width: 400px;
  .name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .remove {
    cursor: pointer;
  }
}
.files-name {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #5f6482;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  font-weight: 600;
  width: max-content;
  max-width: 400px;
  .name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #5f6482;
  }
  .remove {
    cursor: pointer;
  }
}
.file-list {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}
.error-message {
  color: red;
  font-size: var(--font-size-12);
}
