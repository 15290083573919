@import '../../../../../assets/styles/main';
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.subText {
  font-weight: 500;
  font-size: var(--font-size-18);
  color: $primary_color;
  padding-bottom: 20px;
}

.request-cont {
  display: flex;
  flex-direction: column;
  color: $primary_color;
  gap: 1rem;
  .date-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: var(--font-size-14);
      color: $primary_color;
      font-weight: 600;
      span {
        font-weight: 500;
        color: $primary_color;
      }
    }
  }
  .request-subcont {
    display: flex;
    gap: 5px;
    align-items: flex-start;
  }
  .documents-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .accordion_color {
    background-color: #f5f9ff !important;
  }
  .detailsCont {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding-bottom: 0.5rem;
    img {
      height: 35px;
    }
  }
  .customizedTitle_cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }
  .customizedTitle {
    color: $primary_color;
    p {
      font-size: var(--font-size-14);
      font-weight: 600;
    }
    span {
      font-size: var(--font-size-14);
      font-weight: 500;
    }
  }
}
.modal {
  width: clamp(300px, 45vw, 2000px);
  max-width: 1200px;
  @include respond-to(sm) {
    width: clamp(300px, 90vw, 2000px);
  }
}
