@import '../../../../../../assets/styles//main.scss';
.application {
  width: 50%;
   @include respond-to(lg) {
      width: 70%;

  }
  @include respond-to(sm) {
    width: 100%;
  }
}
