@import '../../../../assets/styles/main';

.menu-container {
  position: fixed;
  top: 0;
  left: -130%;
  width: 100%;
  height: 100%;
  background-color: $primary_color;
  color: white;
  transition: left 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  box-shadow: none;
  z-index: 1000;
  overflow: auto;
  @include respond-to(lg) {
    width: 60%;
  }
  @include respond-to(sm) {
    width: 100%;
  }
  &.open {
    left: 0;
    box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
  }

  h2 {
    margin-top: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      gap: 1rem;
      align-items: center;
      padding: 0.5rem 0;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.841);
      }

      img {
        filter: $filter_primary_color;
      }

      p {
        font-size: var(--font-size-16);
        text-transform: capitalize;
        color: $primary_color;
        font-weight: 600;
      }
    }
  }
}

.menu-button {
  position: fixed;
  top: 20px;
  left: 20px;
  transition: left 1s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1001;

  &.open {
    left: 250px;
  }
}

.burger-icon {
  cursor: pointer;
}

.toggleButton {
  position: relative;
  border: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  display: flex;

  .flesh {
    position: absolute;
    top: 6px;
    left: 8px;
    transition: transform 1s ease-in-out;
  }
}

.logo-container-mobile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.navigation {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.aud-logo {
  @include respond-to(lg) {
    filter: $filter_white;
  }
}
