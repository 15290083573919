.un-auth-container {
  width: 100%;
  height: 80dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
