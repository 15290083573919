@import '../../../assets/styles/main';

.tabs {
  width: 100%;
}

.container {
  padding: 1rem 2rem;
  background-color: $white;
  border-radius: 10px;
  @include respond-to(md) {
    padding: 1rem 1rem;
  }
}

.tab-buttons {
  position: relative;
  display: flex;
  text-transform: capitalize;
  overflow-x: auto;
  border-top-left-radius: 5px;
  box-shadow: -12px 0px 12px -12px #00000030;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }

  .tabHead {
    display: flex;
    align-items: flex-end;
    gap: 0.5vw;
    width: max-content;
    min-width: 12rem;
    padding: 0.8rem 1.5rem !important;
    cursor: pointer;
    border: none;
    position: relative;
    color: $disabled;
    font-size: var(--font-size-20);
    font-weight: 600;
    background-color: #ffffff51;
    color: $primary_color;
    border-top-left-radius: 5px;
    min-width: 18rem;
    padding-right: -20px;
    clip-path: polygon(0% 100%, 0% 0%, 79.5% 0%, 100% 100%);
    user-select: none;
    transition: 0.3s all ease-in-out;
    @include respond-to(sm) {
      min-width: 15rem;
    }
    @include respond-to(xs) {
      min-width: 13rem;
    }
    .enum {
      color: $white;
    }

    &.active {
      color: red;
      background-color: $white;

      &:after {
        width: 100%;
        right: 0;
      }

      img {
        filter: $filterRed;
        max-width: 30px;
      }

      .link {
        color: red;
      }

      .enum {
        color: $white;
      }
    }

    img {
      max-width: 30px;
      filter: $filter_primary_color;
    }
  }
}

.tab-content {
  background-color: $white;
  padding: 1.5rem 2rem;
  border-top: none;
  box-shadow: 0px 0px 12px 0px #00000040;
  @include respond-to(sm) {
    padding: 0.5rem;
  }
}

.tab {
  &.active {
    display: block;
  }

  &:not(.active) {
    display: none;
  }
}

.link {
  text-decoration: none;
  text-align: center;
  padding-top: 5px;
  color: $disabled;
}

.enum {
  box-sizing: initial;
  background-color: $disabled;
  border-radius: 50%;
  padding: 4px 2px;
  min-width: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -10px;
  top: 0;
  align-self: center;
}

.removePadding {
  padding: 0;
  margin-top: 2vh;
}
