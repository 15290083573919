.header {
  display: flex;
  justify-content: space-between;
}
.back-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  img {
    height: 20px;
    cursor: pointer;
  }
}
