@import '../../../assets/styles/main';

.list-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  &.primary {
    background-color: #f0f0f0;
  }
  &.secondary {
    .title {
      font-size: 14px;
      font-style: bold;
      font-weight: 700;
      text-transform: capitalize;
      margin-top: 1rem;
    }
  }
  .title-span {
    color: $primary_color;
    font-weight: bold;
  }
}

.list {
  list-style-position: inside;
  font-size: var(--font-size-14);
  text-justify: auto;
  background-color: auto;

  li,
  ul {
    padding-left: 20px;
    text-indent: -20px;
  }
  .list-item-span {
    color: $primary_color;
    font-weight: bold;
  }
}
