@import '../../../assets/styles/main';

.menu-container {
  position: fixed;
  top: 0;
  right: -130%;
  width: 35%;
  height: 100%;
  background-color: $white;
  color: white;
  transition: right 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  box-shadow: none;
  z-index: 1000;
  overflow: auto;
  @include respond-to(xxl) {
    width: 40%;
  }
  @include respond-to(sm) {
    width: 80%;
  }
  @include respond-to(xs) {
    width: 100%;
  }
  &.open {
    right: 0;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    h3 {
      color: black;
      text-transform: capitalize;
    }
    img {
      cursor: pointer;
    }
  }
}
.backdrop {
  background-color: rgba(0, 0, 0, 0.171);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  &.open {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
.slider-content {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  max-height: 65vh;
  overflow: auto;
  padding: 1rem 2px;

  @include respond-to(lg) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.menu-button {
  background-color: red;
  padding: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  img {
    filter: $filter_white;
    height: 22px;
  }
}
