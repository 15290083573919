.email-notification-card {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;

  border-radius: 8px;

  &:hover {
    background-color: #ecf4ff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
}
.active {
  background-color: #ecf4ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.card-image {
  width: 20px;
  height: 20px;
}
.to {
  display: flex;
  justify-content: space-between;
}
.card-text {
  font-size: var(--font-size-12);
  font-weight: 500;
}
.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // gap: 0.3rem;
}

.card-title {
  font-size: var(--font-size-16);
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.card-subTitle {
  font-size: var(--font-size-12);
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.reminder-container {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.reminder-list {
  display: flex;
  gap: 0.5rem;
}

.reminder-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #b3d3fb;
  padding: 4px 10px;
  font-size: var(--font-size-12);
  border-radius: 10px;
}
.attachment-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.1rem;
  img {
    height: 12px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(96%) saturate(0%)
      hue-rotate(283deg) brightness(100%) contrast(103%);
  }
  p {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.icon {
  width: 15px;
  height: 15px;
}

.text {
  font-size: var(--font-size-12);
  font-weight: 600;
  text-transform: capitalize;
}

.round {
  background-color: red;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.emails-bar {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: calc(100vh - 45vh);
  overflow: auto;
}

.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  img {
    height: 30%;
  }
  p {
    font-size: var(--font-size-14);
    font-weight: 600;
  }
}
