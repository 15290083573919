@import '../../../../../../assets/styles/main';

.requests {
  display: flex;
  gap: 1rem;
  @include respond-to(lg) {
    flex-direction: column;
  }
}
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  p{
    font-size: var(--font-size-24);
    text-transform: capitalize;
    font-weight: 700;
  }
}
