@import '../../../../../assets/styles/main';

.input-container {
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 3rem;
    gap: 0.5rem;
    padding: 0.8rem 0.8rem;
    border-radius: 0.5rem;
    border: 1px solid $primary_color;
    .img-input-cont {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      flex: 1;
    }
    .svg {
      width: 20px;
    }
    .input {
      outline: none;
      border: none;
      width: 100%;
      color: $primary_color;
      background-color: transparent;
      // text-transform: capitalize;
      font-size: var(--font-size-14);

      &:focus {
        border-color: $primary_color;
      }

      &::placeholder {
        color: $placeholder;
        font-size: var(--font-size-14);
        text-transform: capitalize;
      }
    }

    .placeholder {
      position: absolute;
      bottom: 2px;
    }
  }
  .password-toggle {
    position: absolute;
    width: 30px;
    height: 90%;
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    top: 0px;
    cursor: pointer;
    background-color: white;
  }
}

.error-message {
  font-size: var(--font-size-12);
  color: red;
}
